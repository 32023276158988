<template>
  <div v-if="awaitBalance>0">
    {{awaitBalance}} руб.
    <b-badge href="#" size="md" variant="success" @click.prevent="sendOk()" class="mr-2" v-if="isSendOk"><b-icon-cash /> ок</b-badge>
  </div>
</template>
<style scoped>
.badge {
  font-size: 120%;
}
</style>
<script>

export default {
  name: "wbviews-cards-list-status",
  props: ["userId"],
  data: () => ({
    isLoading: true,
    isSendOk: true,
    awaitBalance: 0,
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getStatus() {
      if (!this.userId) return false
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/cards/status/${this.userId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.awaitBalance=resp.item
            // if (!resp.status) this.alertMsg('Ошибка загрузки статуса', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    sendOk() {
      console.log('child sendOk')
      this.$emit('sendok', {userId: this.userId, balance: this.awaitBalance})
      this.isSendOk=false
    }
  },
  components: {},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getStatus()
    }

  },
  computed: {

  }
};
</script>
