<template>
  <div v-if="!isLoading">
    <div v-if="user._id">
      <a href="#" :id="`popover-${user._id}-${userId2}`">{{user.name}}</a>

      <b-popover :target="`popover-${user._id}-${userId2}`" triggers="hover" placement="left">
        <template #title>{{ user.name }}</template>
        тел: {{ user.number }}<br>
        статус: {{user.status}}
        <pre>{{user.IPs.join(", ")}}</pre>
        пвз: {{user.deliveryAddress}}<br>
        email: {{user.email}}
      </b-popover>
    </div>
    <div v-else></div>
  </div>
  <div v-else>
    <b-skeleton></b-skeleton>
  </div>
</template>
<style scoped>
.popover.b-popover {
  font-size: 10px;
}
</style>
<script>

export default {
  name: "wbviews-cards-list-user",
  props: ["userId"],
  data: () => ({
    isLoading: true,
    user: {},
    userId2:0

  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getUser() {
      if (!this.userId) {
        this.isLoading=false
        return false
      }
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/user/${this.userId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            this.isLoading=false
            if (resp.status) this.user=resp.item, this.userId2= Math.random()*100000+10000
            if (!resp.status) this.alertMsg('Ошибка загрузки пользователя', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    }
  },
  components: {},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getUser()
    }

  },
  computed: {

  }
};
</script>