<template>
<!--  <b-container>-->
  <b-row>
    <b-col>
      <b-button size="sm" variant="success" @click.prevent="addNewCard()" class="mb-2">Добавить</b-button>
      <b-table
          :items="cards"
          :fields="fields"
          :tbody-tr-class="rowClass"
          stacked="md"
          show-empty
          small
          headVariant="dark"
          responsive
          bordered
      >

        <template #table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key+'_rows'"
              :style="getWidthCol(field.key)"
          >
        </template>

        <template #cell(card)="{item}">
          <b-row>
            <b-col lg="12">
              <b-form-input style="display: inline-block;width: 120px" size="sm" v-model="item.number" @focusout="cardUpdate(item)" placeholder="номер карты (16 цифр)"></b-form-input>
              <b-form-input style="display: inline-block;width: 35px" size="sm" v-model="item.mounth" @focusout="cardUpdate(item)" title="месяц" placeholder="мм"></b-form-input>
              <b-form-input style="display: inline-block;width: 35px" size="sm" v-model="item.year" @focusout="cardUpdate(item)" title="год" placeholder="гг"></b-form-input>
              <b-form-input style="display: inline-block;width: 37px" size="sm" v-model="item.code" @focusout="cardUpdate(item)" title="код" placeholder="cvv"></b-form-input>
            </b-col>
<!--            <b-col lg="6" class="pl-2">-->
<!--              <b-input-group class="mb-1 d-flex justify-content-between" title="срок действия">-->
<!--                <b-form-input size="sm" v-model="item.mounth" @focusout="cardUpdate(item)" class="mr-1" title="месяц" placeholder="мм"></b-form-input> <span style="font-size: 14px">/</span>-->
<!--                <b-form-input size="sm" v-model="item.year" @focusout="cardUpdate(item)" class="ml-1"  title="год" placeholder="гг"></b-form-input>-->
<!--                <b-form-input size="sm" v-model="item.code" @focusout="cardUpdate(item)" class="ml-1" title="код" placeholder="cvv"></b-form-input>-->
<!--              </b-input-group>-->
<!--            </b-col>-->
          </b-row>

        </template>
        <template #cell(indx)="{index}">
          {{index}}
        </template>
        <template #cell(balance)="{item}">
          <b-form-input style="width: 50px" size="sm" v-model="item.balance" placeholder="баланс" @focusout="cardUpdate(item)"></b-form-input>
        </template>

        <template #cell(bank)="{item}">
<!--          <b-form-input size="sm" v-model="item.bank" placeholder="банк"></b-form-input>-->
          <b-form-input list="list-banks" v-model="item.bank" placeholder="банк" @focusout="cardUpdate(item)"></b-form-input>

          <datalist id="list-banks">
            <option v-for="(bank, indx) in banks" :key="`bank-${indx}-${bank}`">{{ bank }}</option>
          </datalist>
        </template>

        <template #cell(people)="{item}">
<!--          <b-form-input size="sm" v-model="item.people" placeholder="Кому принадлежит карта"></b-form-input>-->
          <b-form-input list="list-peoples" v-model="item.people" placeholder="Кому принадлежит карта" @focusout="cardUpdate(item)"></b-form-input>

          <datalist id="list-peoples">
            <option v-for="(people, indx) in peoples" :key="`people-${indx}-${people}`">{{ people }}</option>
          </datalist>
        </template>
        <template #cell(status)="{item}">
          <wbviews-cards-list-status :user-id="item.userId" @sendok="addBalance($event)" />
        </template>
        <template #cell(userId)="{item}">
          <wbviews-cards-list-user :user-id="item.userId" />
        </template>
        <template #cell(actions)="{item}">
<!--          <b-badge href="#" size="sm" variant="success" @click.prevent="save(item)" class="mr-2"><b-icon- /> сохранить</b-badge>-->
          <b-badge href="#" size="sm" variant="danger" @click.prevent="delCard(item)" class="mr-2"><b-icon-trash /> удалить</b-badge>

        </template>
      </b-table>
    </b-col>
  </b-row>
<!--  </b-container>-->
</template>

<script>

import WbviewsCardsListUser from "@/WbViews/Cards/cardsList-user";
import WbviewsCardsListStatus from "@/WbViews/Cards/cardsList-status";
export default {
  name: "wbviews-cards-list",
  props: [],
  data: () => ({
    isLoading: true,
    cards: [],
    newCard: {number:'', balance: '', mounth: '', year: '', code: '', bank:'', people: ''},
    fields: [
      { key: 'indx', label: '#', sortable: false, sortDirection: 'desc' },
      { key: 'card', label: 'Карта', sortable: false, sortDirection: 'desc' },
      { key: 'balance', label: 'Баланс', sortable: false, sortDirection: 'desc' },
      { key: 'bank', label: 'Банк', sortable: false, sortDirection: 'desc' },
      { key: 'people', label: 'Вледелец', sortable: false, sortDirection: 'desc' },
      { key: 'status', label: 'Ждёт баланс', sortable: false, sortDirection: 'desc' },
      { key: 'userId', label: 'Кабинет', sortable: false, sortDirection: 'desc' },
      { key: 'actions', label: '', sortable: false, sortDirection: 'desc' },
    ],
    banks: [],
    peoples: []
  }),
  methods: {
    addBalance(data) {
      try{
        const {userId, balance} = data
        this.cards.find(e=>e.userId==userId).balance=parseInt(this.cards.find(e=>e.userId==userId).balance)+parseInt(balance)
        this.cardUpdate(this.cards.find(e=>e.userId==userId))
      }catch (err) {
        this.alertMsg('Что-то пошло не так', `ошибка: ${err}`, 'danger')
      }

    },
    getWidthCol(key) {
      if (key=='card') return {width: '250px', minWidth: '250px'}
      if (key=='balance') return {width: '100px'}
      if (key=='bank') return {width: '150px'}
      if (key=='people') return {width: '130px'}
      if (key=='status') return {width: '150px'}
      if (key=='userId') return {width: '200px'}
      if (key=='actions') return {width: '80px'}
      if (key=='indx') return {width: '12px'}

    },
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getCards() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/cards`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.banks = Array.from(new Set(resp.items.map(e=>e.bank)))
              this.peoples = Array.from(new Set(resp.items.map(e=>e.people)))
              this.cards=resp.items
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки аккаунтов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    addNewCard() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/getNewId`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.cards.unshift({_id: resp.message, number:'', balance: '', mounth: '', year: '', code: '', bank:'', people: ''})
            if (!resp.status) this.alertMsg('Ошибка', `Не удалось получить id для новой записи ${resp.message}`, 'danger')
          })

    },
    delCard(card) {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/cards`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(card)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Удаление карты | Успешно', `удалена с id: ${resp.message}`, 'success')
              this.cards=this.cards.filter(e=>e._id!=card._id)
            }
            if (!resp.status) this.alertMsg('Ошибка', `Не удалось удалить карту ${resp.message}`, 'danger')
          })

    },
    cardUpdate(card) {
      if (this.validationCard(card)) return false
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/cards`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(card)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.cards.find(e=>e._id==card._id)._id==resp.message
              // this.alertMsg('Обновление карты | Успешно', `обновлёна с id: ${resp.message}`, 'success')
            }
            if (!resp.status) this.alertMsg('Обновление карты | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
          })

      // this.alertMsg('Ошибка обновления', `Ошибка сервера, попробуйте ещё раз`, 'danger')
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.validationCard(item)) return 'table-danger'
      // return 'table-success'
    },
    validationCard(item) {
      if (item) return item.number.length<16 || item.mounth.length!=2 || item.year.length!=2 || item.code.length!=3
    }
  },
  components: {WbviewsCardsListStatus, WbviewsCardsListUser},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getCards()
    }

  },
  computed: {

  }
};
</script>

<style>
input, textarea, div {
  font-size: 10px!important;
}

</style>